/**
 * Load async data for data-async-load attribute
 */

if (document.readyState !== "loading") {
  bcl.u.loadAsyncData();
} else {
  document.addEventListener("DOMContentLoaded", function () {
    bcl.u.loadAsyncData();
  });
}
