import { BarceloComponent } from "barcelo-components";

const StructureComponents = {
  brands: BarceloComponent({
    selector: ".c-brands",
    path: "structure/brands/js/c-brands",
    forceImport: true,
  }),

  megaMenu: BarceloComponent({
    selector: ".c-megamenu",
    path: "structure/header/js/megaMenu",
    belongsTo: "header",
  }),

  asideMenu: BarceloComponent({
    selector: ".c-aside-menu",
    path: "structure/header/js/asideMenu",
    belongsTo: "header",
  }),

  sticky: BarceloComponent({
    selector: ".c-header",
    path: "structure/header/js/sticky",
    belongsTo: "header",
  }),

  language: BarceloComponent({
    selector: ".language-JS",
    path: "structure/language/js/c-language",
    belongsTo: "header",
  }),

  phoneCC: BarceloComponent({
    selector: ".phoneCC",
    path: "structure/header/js/phoneCC",
    belongsTo: "header",
  }),

  header: BarceloComponent({
    selector: ".header",
    path: "structure/header/js/c-header",
    forceImport: true,
  }),

  heroslider: BarceloComponent({
    selector: ".c-heroslider",
    path: "structure/heroslider/js/c-heroslider",
  }),

  hotelMeetingRooms: BarceloComponent({
    selector: ".c-hotel-meeting-rooms",
    path: "structure/hotel-meeting-rooms/js/c-hotel-meeting-rooms",
  }),

  hotelRoyal: BarceloComponent({
    selector: ".hotel-royal",
    path: "structure/hotel-royal/js/hotel-royal-colors",
  }),

  calendar: BarceloComponent({
    selector: ".c-fastbooking__submenu-calendar, .c-fastbooking, .hotel-room-list, .c-hotel-room-list",
    path: "structure/fastbooking/js/calendar",
    belongsTo: "fastbooking",
    skipInit: true,
  }),

  destination: BarceloComponent({
    selector: ".destination-JS, .c-fastbooking, .hotel-room-list, .c-hotel-room-list",
    path: "structure/fastbooking/js/destination",
    belongsTo: "fastbooking",
    skipInit: true,
    forceImport: true,
  }),

  discount: BarceloComponent({
    selector: ".c-fastbooking__submenu-discount, .c-fastbooking, .hotel-room-list, .c-hotel-room-list",
    path: "structure/fastbooking/js/discount",
    belongsTo: "fastbooking",
    skipInit: true,
  }),

  awardsSwiper: BarceloComponent({
    selector: ".c-awards-swipper",
    path: "structure/awards-swipper/js/awards-swipper",
  }),

  rooms: BarceloComponent({
    selector: ".c-fastbooking__submenu-rooms, .c-fastbooking, .hotel-room-list, .c-hotel-room-list",
    path: "structure/fastbooking/js/rooms",
    belongsTo: "fastbooking",
    skipInit: true,
  }),

  highlightsGallery: BarceloComponent({
    selector: ".c-highlights-swiper",
    path: "structure/highlights-gallery/js/highlights-gallery",
  }),

  fastbooking: BarceloComponent({
    selector: ".fastbooking, .c-fastbooking, .hotel-room-list, .c-hotel-room-list",
    path: "structure/fastbooking/js/c-fastbooking",
    forceImport: true,
  }),

  customModal: BarceloComponent({
    selector: ".tingle-custom-modal-JS",
    path: "structure/custom-modal/js/custom-modal",
  }),

  headerDetail: BarceloComponent({
    selector: ".c-header-detail, .wizardModal-JS",
    path: "structure/header-detail/js/c-header-detail",
  }),

  campaignGenericComponentGroup: BarceloComponent({
    selector: ".campaign-group-fb-JS",
    path: "structure/campaign-generic-group/js/c-campaign-generic-component-group",
  }),

  localeConfigurationForm: BarceloComponent({
    selector: ".locale-form-JS",
    path: "structure/locale-configuration-form/js/c-locale-configuration-form",
  }),

  footer: BarceloComponent({
    selector: "footer",
    path: "structure/footer/js/footer",
    forceImport: true,
  }),

  headHotelInfo: BarceloComponent({
    selector: ".c-head-hotel-info",
    path: "structure/head-hotel-info/js/c-head-hotel-info",
  }),
};

export default StructureComponents;
