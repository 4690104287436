import { BarceloComponent } from "barcelo-components";

const VariousComponents = {
  languageDialog: BarceloComponent({
    selector: ".language-dialog-container-JS",
    path: "various/language-dialog/js/c-language-dialog",
  }),

  currencyDialog: BarceloComponent({
    selector: ".currency-dialog-container-JS",
    path: "various/currency-dialog/js/c-currency-dialog",
  }),

  opinion: BarceloComponent({
    selector: ".cmp-bhg-opinion",
    path: "various/opinion/js/opinion",
  }),

  phoneContact: BarceloComponent({
    selector: ".bcom-phone-contact.c-phone-contact",
    path: "various/phone-contact/js/phone-contact",
  }),

  socialRoom: BarceloComponent({
    selector: ".c-social-room",
    path: "various/social-room/js/social-room",
  }),

  interactiveMap: BarceloComponent({
    selector: ".interactive-map",
    path: "various/interactive-map/js/interactive-map-new",
  }),

  customChrono: BarceloComponent({
    selector: ".custom-chrono-JS",
    path: "various/custom-chrono/js/custom-chrono",
  }),

  carouselExtras: BarceloComponent({
    selector: ".JS-carousel-extras-swiper",
    path: "various/carousel-with-extras/js/carousel-with-extras",
    forceImport: true,
  }),
};

export default VariousComponents;
