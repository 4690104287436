import { BarceloComponent } from "barcelo-components";

const ListingComponents = {
  feedEntries: BarceloComponent({
    selector: ".c-feed-entries",
    path: "listing/feed-entries/js/c-feed-entries",
    forceImport: true,
  }),

  cardHotelList: BarceloComponent({
    selector: ".c-card-hotel-list__content, .c-card-hotel-list",
    path: "listing/card-hotel-list/js/card-hotel-list",
  }),

  collectionsListing: BarceloComponent({
    selector: ".JS-offers-collections",
    path: "listing/offer-collection-list/js/c-offer-collection-list",
  }),

  offerDestination: BarceloComponent({
    selector: ".JS-offers-destination",
    path: "listing/offers-destination/js/c-offers-destination",
  }),

  promoList: BarceloComponent({
    selector: ".c-promo-list",
    path: "listing/promo-list/js/promo-list",
  }),

  swiperCollectionList: BarceloComponent({
    selector: ".JS-collection-link",
    path: "listing/swipper-collection-list/js/swipper-collection-list",
  }),

  campaignList: BarceloComponent({
    selector: ".campaign-list",
    path: "listing/campaign-list/js/c-campaign-list",
  }),
};

export default ListingComponents;
