import { dynamicImport, isPresent as isComponentPresent } from "components-utils";

const BhgComponent = ({ selector, path, initMethod = "init", skipInit = false, belongsTo = "", customName = "", forceImport = false }) => ({
  forceImport,

  isPresent: () => forceImport || isComponentPresent(selector),

  importComponent: async ({ name, root }) => {
    const component = await import(`./${path}.js`);
    return dynamicImport({
      name,
      customName,
      belongsTo,
      component,
      initMethod,
      skipInit,
      root,
    });
  },

  componentClass: () => ({
    selector,
  }),
});

const components = {
  assetVideo: BhgComponent({ selector: ".c-video, .gallery", path: "asset-video" }),
  genericText: BhgComponent({ selector: ".cmp-text", path: "genericText" }),
};

export default components;
