import { BarceloComponent } from "barcelo-components";

const TeasersComponents = {
  carousel: BarceloComponent({ selector: ".cmp-bhg-carousel", path: "teasers/carousel/js/c-carousel" }),
  coupon: BarceloComponent({ selector: ".c-coupon", path: "teasers/coupon/js/c-coupon" }),
  servicesCarousel: BarceloComponent({ selector: ".c-services-carousel", path: "teasers/services-carousel-cards/js/c-services-carousel-cards" }),
  card: BarceloComponent({ selector: [".text-desktop-JS", ".text-mobile-JS"], path: "teasers/card/js/c-card" }),
  launcher: BarceloComponent({ selector: ".launcher, .c-launcher, .JS-c-launcher-link", path: "teasers/launcher/js/c-launcher" }),
  menuCards: BarceloComponent({ selector: ".menu-cards", path: "teasers/menu-cards/js/menu-cards" }),
  experienceCards: BarceloComponent({ selector: ".experience-cards", path: "teasers/experience-cards/js/experience-cards" }),
  gallerySummary: BarceloComponent({ selector: ".c-gallery-summary", path: "teasers/gallery-summary/js/c-gallery-summary" }),
};

export default TeasersComponents;
