import { BarceloComponent } from "barcelo-components";

const AllContainers = {
  container: BarceloComponent({
    selector: [".c-mask", ".generic-container.mod--separator", ".c-container"],
    path: "containers/generic-container/js/c-container",
  }),

  swiper: BarceloComponent({
    selector: ".c-swiper, .swiper-wrapper",
    path: "containers/swiper/js/c-swiper",
  }),

  accordion: BarceloComponent({
    selector: ".accordion-item",
    path: "containers/accordion-item/js/c-accordion",
  }),

  tabsdropdown: BarceloComponent({
    selector: ".c-tabsdropdown",
    path: "containers/dropdown/js/c-dropdown",
  }),

  tabMenu: BarceloComponent({
    selector: ".c-tab-menu",
    path: "containers/tab-menu/tab-menu/js/c-tab-menu",
  }),

  launchersContainer: BarceloComponent({
    selector: ".launchers-container",
    path: "containers/launchers-container/js/launchers-container",
  }),
};

export default AllContainers;
