import { BarceloComponent } from "barcelo-components";

const MenuComponents = {
  stripeMenu: BarceloComponent({
    selector: ".c-stripemenu, .stripemenu",
    path: "menu/stripemenu/js/c-stripemenu",
  }),

  vMenu: BarceloComponent({
    selector: ".c-submenu-container, .c-v-menu, .header, .c-header",
    path: "menu/v-menu/js/v-menu",
  }),
};

export default MenuComponents;
