const loadedComponents = {};

const loadComponent = async (name, component, options, retries = 10) => {
  if (!component || (options?.ignoreIsPresent !== true && !component.isPresent(options))) {
    return false;
  }

  for (let attempt = 1; attempt <= retries; attempt++) {
    try {
      const importedComponent = await component.importComponent({
        name,
        root: options?.root,
      });
      return !!importedComponent;
    } catch (error) {
      if (attempt === retries) {
        console.error(`Error importing component '${name}':`, error);
        return false;
      }
      await new Promise((res) => setTimeout(res, 100 * attempt));
    }
  }
};

const handleConditionalLoad = (name, component, options) => {
  const root = options?.root || document;
  const elements = [...root.querySelectorAll(component.componentClass().selector)];

  if (!elements.length) {
    return Promise.resolve(false);
  }

  const isInViewport = (elem) => {
    const rect = elem.getBoundingClientRect();
    return rect.top >= 0 && rect.left >= 0 && rect.bottom <= (window.innerHeight || document.documentElement.clientHeight) && rect.right <= (window.innerWidth || document.documentElement.clientWidth);
  };

  if (elements.some((el) => isInViewport(el))) {
    return loadComponent(name, component, options);
  }

  if ("IntersectionObserver" in window) {
    const observer = new IntersectionObserver((entries, obs) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          obs.disconnect();
          loadComponent(name, component, options);
        }
      });
    });
    elements.forEach((el) => observer.observe(el));
  } else {
    loadComponent(name, component, options);
  }

  return Promise.resolve(false);
};

const BarceloComponents = {
  init: async (components, options = { root: document, forceImport: false }) => {
    const loadPromises = Object.entries(components).map(async ([name, component]) => {
      if (!component || typeof component.isPresent !== "function") {
        // console.warn(`The component "${name}" is undefined or does not have isPresent. Skipping.`);
        loadedComponents[name] = false;
        return;
      }

      if (options.forceImport || component.forceImport) {
        // console.log(`Forcing import of ${name}`);

        const isLoaded = await loadComponent(name, component, {
          ...options,
          ignoreIsPresent: true,
        });
        loadedComponents[name] = isLoaded;
      } else {
        if (component.isPresent(options)) {
          // console.log(`Loading ${name}`);
          const isLoaded = await handleConditionalLoad(name, component, options);
          loadedComponents[name] = isLoaded;
        } else {
          // console.log(`Skipping ${name}`);
          loadedComponents[name] = false;
        }
      }
    });

    await Promise.all(loadPromises);

    bcl.c.manifest = { ...bcl.c.manifest, ...loadedComponents };
  },
};

export default BarceloComponents;
